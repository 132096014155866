
import {
  Component, Vue, Prop, Emit,
} from "vue-property-decorator";
import { Action } from "vuex-class";
import { Team } from "@/model/team.model";
import { Nomination } from "@/model/nomination.model";
import { getNominationModuleNamespace } from "@/store/store.utils";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

@Component({})
export default class KickManagerDialog extends Vue {
  open = false;

  step = 1;

  loading = false;

  remain = true;

  @Prop()
  pTeam!: Team;

  @Prop()
  pNominations!: Nomination[]

  @Action("kickManager", getNominationModuleNamespace())
  kickManager!: ({ team, remain }: { team: Team; remain: boolean }) => Promise<void>;

  @Emit("onUpdate")
  emitOnUpdate() {
    return true;
  }

  get hasActiveMembers() {
    return this.pNominations.some(it => it.isActive && it.isMember);
  }

  get firstStepText() {
    return this.hasActiveMembers ? "This team has active members, data will be preserved." : "This team has no active members.";
  }

  get remainSwitcherText() {
    return this.remain ? "Yes" : "No";
  }

  get stepsEnded() {
    return this.step > 2;
  }

  get isFinalStep() {
    return this.step == 2;
  }

  async action() {
    if (this.isFinalStep) {
      this.loading = true;

      try {
        await this.kickManager({ team: this.pTeam, remain: this.remain });
        this.emitOnUpdate();
        this.closeDialog();
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
      return;
    }

    this.step++;
  }

  closeDialog() {
    this.open = false;
  }

  openDialog() {
    this.open = true;
    this.remain = true;
    this.step = +this.hasActiveMembers + 1;
  }
}
